import * as anchor from '@project-serum/anchor';
import { ref } from 'vue';
import { notify } from '@kyvg/vue3-notification'
import { programs } from '@metaplex/js'
const { metadata: { MetadataProgram, Metadata } } = programs

const rpcHost = process.env.VUE_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
export const MAX_NAME_LENGTH = 32;
export const MAX_URI_LENGTH = 200;
export const MAX_SYMBOL_LENGTH = 10;
export const MAX_CREATOR_LEN = 32 + 1 + 1;

export async function fetchHashTable(hash, metadataEnabled) {
    const metadataAccounts = await MetadataProgram.getProgramAccounts(
        connection,
        {
            filters: [
                {
                    memcmp: {
                        offset:
                            1 +
                            32 +
                            32 +
                            4 +
                            MAX_NAME_LENGTH +
                            4 +
                            MAX_URI_LENGTH +
                            4 +
                            MAX_SYMBOL_LENGTH +
                            2 +
                            1 +
                            4 +
                            0 * MAX_CREATOR_LEN,
                        bytes: hash,
                    },
                },
            ],
        }
    );

    const mintHashes = [];

    for (let index = 0; index < metadataAccounts.length; index++) {
        const account = metadataAccounts[index];
        const accountInfo = await connection.getParsedAccountInfo(
            account.pubkey
        );
        const metadata = new Metadata(hash.toString(), accountInfo.value);
        if (metadataEnabled) mintHashes.push(metadata.data);
        else mintHashes.push(metadata.data.mint);
    }

    return mintHashes;
}

export function useHashTable(candyMachineId, metadataEnabled) {
    const hashTable = ref([])
    const loading = ref(false)

    const getHashTable = async () => {
        if (!candyMachineId || !candyMachineId.length) {
            notify({ type: 'error', text: 'Please type the Candy Machine ID in the input box' });

            return;
        }
        try {
            loading.value = true
            hashTable.value = await fetchHashTable(candyMachineId, metadataEnabled);
            if (hashTable.value.length === 0) {
                notify({ type: 'info', text: 'Zero mint hashes have been found so far for this candy machine' });
            }
        } catch (error) {
            console.error(error);
            notify({ type: 'error', text: 'An error happened! Please try again later!' });
        }
        loading.value = false
    };

    return { hashTable, loading, getHashTable };
}