import { ref, watchEffect } from 'vue'
import { useWallet } from '@solana/wallet-adapter-vue'
import * as anchor from '@project-serum/anchor'
import { getNFTsForOwner } from '../mint/candy-machine'

const rpcHost = process.env.VUE_APP_SOLANA_RPC_HOST
const connection = new anchor.web3.Connection(rpcHost)

const useWalletNfts = () => {
  const wallet = useWallet()
  const loading = ref(false)
  const nfts = ref([])

  const fetchTokens = async () => {
    if (!wallet || !wallet.publicKey.value) return

    loading.value = true
    nfts.value = await getNFTsForOwner(connection, wallet.publicKey.value)
    loading.value = false
  }

  watchEffect(fetchTokens)

  return { loading, nfts, refetch: fetchTokens }
};

export default useWalletNfts;