<template>
  <WalletProvider :wallets="wallets" auto-connect>
    <WalletModalProvider>
      <Mint
        :candy-machine-id="candyMachineId"
        :config="config"
        :connection="connection"
        :start-date="startDate"
        :treasury="treasury"
        :tx-timeout="txTimeout" />
    </WalletModalProvider>
  </WalletProvider>
</template>

<script setup>
import { computed } from 'vue'
import * as anchor from '@project-serum/anchor'
import { WalletProvider } from '@solana/wallet-adapter-vue'
import { WalletModalProvider } from '@solana/wallet-adapter-vue-ui'
import { getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet, } from '@solana/wallet-adapter-wallets'

import '@/assets/css/solana-wallet-adapter.css'

import Mint from './Mint.vue'
import Tokens from './Tokens.vue'

const treasury = new anchor.web3.PublicKey(
  process.env.VUE_APP_TREASURY_ADDRESS
)

const config = new anchor.web3.PublicKey(
  process.env.VUE_APP_CANDY_MACHINE_CONFIG
)

const candyMachineId = new anchor.web3.PublicKey(
  process.env.VUE_APP_CANDY_MACHINE_ID
)

const network = process.env.VUE_APP_SOLANA_NETWORK
const rpcHost = process.env.VUE_APP_SOLANA_RPC_HOST
const connection = new anchor.web3.Connection(rpcHost)

const startDate = parseInt(process.env.VUE_APP_CANDY_START_DATE, 10)
const txTimeout = 30000 // ms

const wallets = computed(() => ([
  getPhantomWallet(),
  getSlopeWallet(),
  getSolflareWallet(),
  getLedgerWallet(),
  getSolletWallet({ network }),
  getSolletExtensionWallet({ network }),
]))
</script>
