<template>
  <!-- <HeroCard /> -->

  <!-- Section 1 -->
  <section class="relative px-6 pt-3 bg-gradient-to-b from-white to-gray-50 z-0 xl:px-8">
    <div class="max-w-5xl mx-auto relative z-10 pt-8 mx-auto text-left md:text-center">
      <div class="relative max-w-4xl mx-auto">
        <h1 class="pb-2 text-4xl font-extrabold text-left text-transparent sm:text-5xl sm:text-6xl md:text-7xl md:leading-tight md:text-center bg-clip-text bg-gradient-to-br from-green-400 via-green-600 to-green-300">
          Welcome to the Space Snek Society.
        </h1>

        <p class="mt-8 text-base text-left text-gray-500 sm:text-lg md:text-xl md:max-w-xl md:text-center md:mx-auto">
          An ultra exclusive society of 1,024 highly evolved Sneks, that travelled the cosmos to open the Diamond Hands Hotel
        </p>
      </div>

      <div class="box-content relative w-full max-w-6xl pb-0 mx-auto mt-16 overflow-hidden rounded-b-none pt-4 lg:pb-20">
        <div class="grid w-full grid-cols-2 gap-8 text-left md:grid-cols-3 xl:grid-cols-4">
          <div class="row-span-2 col-span-1 md:col-start-1 sm:row-start-2 md:row-start-3">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/19.png" alt="">
            </div>
          </div>
          <div class="row-span-2 col-span-1 md:col-start-1 xl:col-start-2 sm:row-start-4 md:row-start-5 xl:row-start-2">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/2.png" alt="">
            </div>
          </div>
          <div class="row-span-2 col-span-1 md:col-start-2 xl:col-start-2 sm:row-start-6 md:row-start-2 xl:row-start-4">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/rare-1.png" alt="">
            </div>
          </div>
          <div class="row-span-2 col-span-1 md:col-start-2 xl:col-start-3 sm:row-start-1 md:row-start-4 xl:row-start-1">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/16.png" alt="">
            </div>
          </div>
          <div class="row-span-2 col-span-1 md:col-start-3 xl:col-start-3 sm:row-start-3 md:row-start-1 xl:row-start-3">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/rare-3.png" alt="">
            </div>
          </div>
          <div class="row-span-2 col-span-1 md:col-start-3 xl:col-start-4 sm:row-start-5 md:row-start-3 xl:row-start-2">
            <div class="relative flex flex-col items-start justify-end w-full h-full overflow-hidden bg-black shadow-lg rounded-xl hover:shadow-xl" style="aspect-ratio: 1/1;">
              <img class="absolute inset-0 object-cover object-center w-full h-full transform duration-500 hover:scale-105" src="@/assets/img/sneks/rare-4.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <img class="absolute left-0 z-0 w-3/4 transform -translate-y-1/2 opacity-60 top-1/2" src="@/assets/img/gradient-blob.svg">
    <img class="absolute left-0 z-0 object-cover object-center w-full h-full opacity-50 top-24" src="https://cdn.devdojo.com/tails/images/swirl-white.svg">
  </section>

  <div class="bg-gray-50 pt-12 sm:pt-16">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Space Sneks are minting now
        </h2>
        <p class="mt-3 text-xl text-gray-500 sm:mt-4">
          Sneks are the only way to get instant presale access to all our future drops
        </p>
      </div>
    </div>
    <div class="mt-10 pb-12 bg-white sm:pb-16">
      <div class="relative">
        <div class="absolute inset-0 h-1/2 bg-gray-50" />
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto">
            <MintProvider />
          </div>
        </div>
      </div>
    </div>
  </div>

  <header class="mt-8 lg:mt-0">
    <div class="w-full h-full">
      <div class="mx-auto max-w-7xl lg:px-6">
        <div class="flex flex-col w-full lg:flex-row lg:space-x-10 lg:mt-10 sm:pb-10">
          <div class="flex flex-col items-center justify-between w-full p-0 overflow-hidden bg-green-100 lg:rounded-2xl sm:flex-row lg:justify-start sm:p-10">
            <div class="relative flex items-center p-8 sm:p-0">
              <img src="https://d17v9ds8p1uxd3.cloudfront.net/img_emoji/em-29.png?versionId=kzO4MlDfPaYXz3LFWdkA5xLgTN_YzBoH" class="w-20 mr-5">
              <div class="relative pr-5">
                <h2 class="font-medium text-green-800">Sneks are a Mint Pass</h2>
                <p class="mt-1 text-sm text-green-600">All holders get presale access to our future influencers collections with a Space Snek.</p>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center justify-between w-full p-0 overflow-hidden bg-gray-100 lg:rounded-2xl sm:flex-row lg:justify-start sm:p-10">
            <div class="relative flex items-center p-8 sm:p-0">
              <img src="https://d17v9ds8p1uxd3.cloudfront.net/img_emoji/em-31.png?versionId=KkxwstiX2hD8e2CEDP7lfnIvVt1h_T1Q" class="w-20 mr-5">
              <div class="relative pr-5">
                <h2 class="font-medium text-gray-800">Made by Diamond Hands Hotel</h2>
                <p class="mt-1 text-sm text-gray-600">
                  We help create NFT collections for influencers & celebrities.
                </p>
              </div>
            </div>
            <a href="https://diamondhandshotel.com/lobby" class="inline-flex justify-center flex-shrink-0 w-full px-5 py-3 mt-2 text-xs font-medium text-center text-white uppercase bg-gray-400 sm:rounded-full sm:w-auto">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="py-20 bg-white">
    <div class="max-w-7xl px-10 mx-auto sm:px-20 md:px-32 lg:px-8">
      <div class="flex flex-wrap items-center -mx-3">
        <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
          <div class="w-full lg:max-w-md">
            <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">
              Space Sneks is the genesis collection from Diamond Hands Hotel
            </h2>
            <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">
              We're disrupting the creator economy with an NFT-powered video, live-streaming and rewards platform. We help influencers and celebrities:
            </p>
            <ul>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <CubeTransparentIcon class="w-8 h-8 text-pink-500" />
                <span class="font-medium text-gray-500">Design, mint and sell NFT collections</span>
              </li>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <LockOpenIcon class="w-8 h-8 text-yellow-500" />
                <span class="font-medium text-gray-500">Unlock exclusive content, events & more with NFTs</span>
              </li>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <ChartBarIcon class="w-8 h-8 text-green-500" />
                <span class="font-medium text-gray-500">Build, grow and connect with their audience</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
          <img class="mx-auto sm:max-w-sm rounded-lg lg:max-w-full" src="@/assets/img/sneks-painting.png" alt="Community art">
          <p class="mt-2 text-sm font-medium text-gray-400 text-right">Community art by <a href="https://www.deviantart.com/brissytoe" target="_blank" class="text-gray-800">BrissyToe</a></p>
        </div>
      </div>
    </div>
  </section>

  <div class="relative pt-16 pb-32 bg-white">
    <div class="max-w-7xl mx-auto bg-green-600 lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
          <div class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
          <div class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
            <div class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
              <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1172801188&color=%2310b981&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" class="rounded-lg overflow-hidden"></iframe>
            </div>
          </div>
        </div>

        <div class="relative bg-green-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
          <div class="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
            <svg class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-green-500" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
            <svg class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-green-500" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
          </div>
          <div class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
            <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading text-white" id="join-heading">Community that vibes</h2>
            <p class="text-lg text-white">
              We've got it all. Daily community events and games, art competitions, meme battles and even a rapper creating all original snake jazz.
            </p>
            <a class="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-green-700 hover:bg-gray-50 sm:inline-block sm:w-auto" href="https://discord.gg/4SSJWSdsUk" target="_blank">Join the Discord</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="bg-gray-50">
    <div class="px-10 py-16 ml-auto mr-auto max-w-7xl md:px-24 lg:px-12 lg:py-28">
      <div class="grid gap-5 lg:grid-cols-2">
        <div class="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
          <div class="flex items-center justify-center w-16 h-16 mb-5 rounded-full bg-gradient-to-tr from-green-600 to-green-500">
            <TicketIcon class="w-10 h-10 text-white" />
          </div>
          <div class="max-w-xl mb-6">
            <div class="mb-6">
              <p class="inline font-sans text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">Some pretty mint&nbsp;</p>
              <p class="inline font-sans text-5xl font-bold tracking-tight text-green-600 sm:text-6xl sm:leading-none">mint passes</p>
            </div>
            <p class="text-base text-gray-700 md:text-lg">
              Sneks aren't just mint passes for the future. They've got their own lore, and with four factions vying for control of The Hotel there is bound to be drama...
            </p>
          </div>
          <div class="max-w-xl mb-6">
            <p class="relative">
              <a href="https://sneks.diamondhandshotel.com/story" target="_blank" class="inline-flex flex-col items-center font-semibold text-green-700 transition-colors duration-200 cursor-pointer group">
                <span class="flex items-center w-full">
                  <span>Read the story</span>
                  <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12"><path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path></svg>
                </span>
                <span class="w-full h-0.5 translate-y-2 group-hover:translate-y-1 duration-200 ease-out transition opacity-0 group-hover:opacity-100 block bg-green-600"></span>
              </a>
            </p>
          </div>
        </div>
        <div class="flex justify-center w-full items-center">
          <div class="flex flex-col items-end pr-3">
            <img src="@/assets/img/sneks/21.png" class="object-cover w-full h-full mb-6 rounded-lg shadow-lg lg:h-48 xl:h-56 lg:w-48 xl:w-56">
            <img src="@/assets/img/sneks/rare-6.png" class="object-cover w-full h-full rounded-lg shadow lg:h-32 xl:h-40 lg:w-32 xl:w-40">
          </div>
          <div class="pl-3">
            <img src="@/assets/img/sneks/23.png" class="object-cover w-full h-full rounded-lg shadow-lg lg:h-64 xl:h-80 lg:w-64 xl:w-80">
          </div>
        </div>
      </div>
    </div>
  </section>



<!-- Section 1 -->


<!-- Section 2 -->
<section class="w-full py-32 bg-gray-900">
    <div class="relative max-w-5xl px-12 mx-auto xl:px-0">
        <h2 class="font-bold text-white text-7xl sm:text-8xl">We're just getting started.</h2>
        <div class="my-20 border border-gray-800"></div>
        <div class="flex flex-col items-center lg:flex-row">
            <div class="relative w-full lg:w-2/3">
                <p class="text-2xl font-normal text-gray-300">
                  We've got big plans for The Hotel, and Space Sneks is just the beginning. Check out the website to see
                  the full roadmap.
                </p>
                <p class="mt-12 text-sm uppercase text-green-500">
                  Make sure to follow @DiamondHH on Twitter
                </p>
            </div>
            <div class="flex items-center w-full h-full mt-16 lg:w-1/3 lg:mt-0">
                <a href="https://sneks.diamondhandshotel.com/#roadmap" class="w-full px-12 py-6 text-2xl text-center text-white rounded-full md:w-auto bg-green-700">
                  Learn more
                </a>
            </div>
        </div>
    </div>
</section>

<!-- 

  <DSidebarLayout>
    <section class="px-4 my-6 sm:px-6 lg:px-8">
      
      <template v-if="breakpoint.lgAndUp">
        <QuickLinksCard />
        <GameCta />
        <BenefitsCard />
        <QuoteCard />
      </template>
      <template v-else>
        <CountdownCard />
        <SocialLinksCard />
        <QuickLinksCard />
        <GameCta />
        <BenefitsCard />
        <QuoteCard />
      </template>
    </section>

    <template #sidebar>
      <div class="p-6 space-y-6">
        <template v-if="breakpoint.lgAndUp">
          <CountdownCard />
          <SocialLinksCard />
        </template>
        <WhitelistReqCard />
        <MailingListCard />
      </div>
    </template>
  </DSidebarLayout> -->
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { CubeTransparentIcon, LockOpenIcon, ChartBarIcon, TicketIcon, SparklesIcon, ArrowRightIcon } from '@heroicons/vue/outline'
import AppLink from '@/components/roof/AppLink.vue'
import ConfettiExplosion from "vue-confetti-explosion"

const visible = ref(false)
const explode = async () => {
  visible.value = false
  await nextTick()
  visible.value = true
}

import { ArrowLeftIcon } from '@heroicons/vue/outline'
import { DSidebarLayout, useBreakpoint } from '@ui'

// Main
import QuickLinksCard from '@/components/roof/drops/presale/QuickLinksCard.vue'
import GameCta from '@/components/roof/drops/presale/GameCta.vue'
import BenefitsCard from '@/components/roof/drops/presale/BenefitsCard.vue'
import QuoteCard from '@/components/roof/drops/presale/QuoteCard.vue'
import HeroCard from './HeroCard.vue'
import MintProvider from './mint2/MintProvider.vue'

// Sidebar
import CountdownCard from '@/components/roof/drops/presale/CountdownCard.vue'
import SocialLinksCard from '@/components/roof/drops/presale/SocialLinksCard.vue'
import WhitelistReqCard from '@/components/roof/drops/presale/WhitelistReqCard.vue'
import MailingListCard from '@/components/roof/explore/MailingListCard.vue'

const breakpoint = useBreakpoint()
</script>