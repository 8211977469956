import * as anchor from '@project-serum/anchor'
import { MintLayout, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token'
import { sendTransactions, sleep } from './util'
import { fetchHashTable } from '../hooks/use-hash-table'
import { programs } from '@metaplex/js'
const { metadata: { Metadata } } = programs

// unknown
export const CANDY_MACHINE_PROGRAM = new anchor.web3.PublicKey(
  'cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ'
)

// unknown
export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
)

// unknown
export const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
)

const createAssociatedTokenAccountInstruction = (associatedTokenAddress, payer, walletAddress, splTokenMintAddress) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    data: Buffer.from([]),
  });
}

export const getCandyMachineState = async (anchorWallet, candyMachineId, connection) => {
  console.log(`getCandyMachineState()`)
  console.log(`anchorWallet`, anchorWallet)
  console.log(`candyMachineId`, candyMachineId)
  console.log(`connection`, connection)
  
  const provider = new anchor.Provider(connection, anchorWallet, {
    preflightCommitment: 'recent',
  });

  const idl = await anchor.Program.fetchIdl(
    CANDY_MACHINE_PROGRAM,
    provider
  );

  const program = new anchor.Program(idl, CANDY_MACHINE_PROGRAM, provider);
  const candyMachine = {
    id: candyMachineId,
    connection,
    program,
  }

  const state = await program.account.candyMachine.fetch(candyMachineId);

  const itemsAvailable = state.data.itemsAvailable.toNumber();
  const itemsRedeemed = state.itemsRedeemed.toNumber();
  const itemsRemaining = itemsAvailable - itemsRedeemed;

  let goLiveDate = state.data.goLiveDate.toNumber();
  goLiveDate = new Date(goLiveDate * 1000);

  return {
    candyMachine,
    itemsAvailable,
    itemsRedeemed,
    itemsRemaining,
    goLiveDate,
  };
}

const getMasterEdition = async (mint) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('edition'),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

const getMetadata = async (mint) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

const getTokenWallet = async (wallet, mint) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0];
};

export const mintTokens = async (candyMachine, config, payer, treasury, quantity) => {
  const signersMatrix = [];
  const instructionsMatrix = [];

  for (let index = 0; index < quantity; index++) {
      const mint = anchor.web3.Keypair.generate();
      const token = await getTokenWallet(payer, mint.publicKey);
      const { connection } = candyMachine;
      const rent = await connection.getMinimumBalanceForRentExemption(
          MintLayout.span
      );
      const instructions = [
          anchor.web3.SystemProgram.createAccount({
              fromPubkey: payer,
              newAccountPubkey: mint.publicKey,
              space: MintLayout.span,
              lamports: rent,
              programId: TOKEN_PROGRAM_ID,
          }),
          Token.createInitMintInstruction(
              TOKEN_PROGRAM_ID,
              mint.publicKey,
              0,
              payer,
              payer
          ),
          createAssociatedTokenAccountInstruction(
              token,
              payer,
              payer,
              mint.publicKey
          ),
          Token.createMintToInstruction(
              TOKEN_PROGRAM_ID,
              mint.publicKey,
              token,
              payer,
              [],
              1
          ),
      ];
      const masterEdition = await getMasterEdition(mint.publicKey);
      const metadata = await getMetadata(mint.publicKey);

      instructions.push(
          await candyMachine.program.instruction.mintNft({
              accounts: {
                  config,
                  candyMachine: candyMachine.id,
                  payer: payer,
                  wallet: treasury,
                  mint: mint.publicKey,
                  metadata,
                  masterEdition,
                  mintAuthority: payer,
                  updateAuthority: payer,
                  tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                  tokenProgram: TOKEN_PROGRAM_ID,
                  systemProgram: anchor.web3.SystemProgram.programId,
                  rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                  clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
              },
          })
      );
      const signers = [mint];

      signersMatrix.push(signers);
      instructionsMatrix.push(instructions);
  }

  return sendTransactions(
      candyMachine.program.provider.connection,
      candyMachine.program.provider.wallet,
      instructionsMatrix,
      signersMatrix
  );
}


export async function getNFTsForOwner(connection, ownerAddress) {
    const allMintsCandyMachine = await fetchHashTable(
        process.env.VUE_APP_CANDY_MACHINE_ID
    );
    const allTokens = [];
    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        ownerAddress,
        {
            programId: TOKEN_PROGRAM_ID,
        }
    );

    for (let index = 0; index < tokenAccounts.value.length; index++) {
        const tokenAccount = tokenAccounts.value[index];
        const tokenAmount = tokenAccount.account.data.parsed.info.tokenAmount;

        if (
            tokenAmount.amount == '1' &&
            tokenAmount.decimals == '0' &&
            allMintsCandyMachine.includes(
                tokenAccount.account.data.parsed.info.mint
            )
        ) {
            let [pda] = await anchor.web3.PublicKey.findProgramAddress(
                [
                    Buffer.from('metadata'),
                    TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                    new anchor.web3.PublicKey(
                        tokenAccount.account.data.parsed.info.mint
                    ).toBuffer(),
                ],
                TOKEN_METADATA_PROGRAM_ID
            );
            const accountInfo = await connection.getParsedAccountInfo(pda);

            const metadata = new Metadata(
                ownerAddress.toString(),
                accountInfo.value
            );
            const dataRes = await fetch(metadata.data.data.uri);
            if (dataRes.status === 200) {
                allTokens.push(await dataRes.json());
            }
        }
    }

    return allTokens;
}


export const shortenAddress = (address, chars = 4) => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};


