<template>
  <form @submit="save">
    <div class="sm:flex sm:items-start">
      <span class="mx-auto flex-shrink-0 bg-green-500 inline-flex items-center justify-center h-12 w-12 rounded-lg">
        <IdentificationIcon class="h-6 w-6 text-white" aria-hidden="true" />
      </span>
      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
          Register for whitelist
        </DialogTitle>
        <div class="mt-1">
          <p class="max-w-2xl text-sm text-gray-500">
            Make sure you've completed the whitelist requirements & have the Sneklisted role in Discord.
          </p>
          <div class="py-6">
            <RadioGroup v-model="data.quantity" required>
              <RadioGroupLabel class="sr-only">
                Pricing plans
              </RadioGroupLabel>
              <div class="relative bg-white rounded-md -space-y-px">
                <RadioGroupOption as="template" v-for="(plan, planIdx) in plans" :key="plan.name" :value="plan.quantity" v-slot="{ checked, active }">
                  <div :class="[planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-green-50 border-green-200 z-10' : 'border-gray-200', 'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none']">
                    <div class="flex items-center text-sm">
                      <span :class="[checked ? 'bg-green-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-green-500' : '', 'h-4 w-4 rounded-full border flex items-center justify-center']" aria-hidden="true">
                        <span class="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroupLabel as="span" :class="[checked ? 'text-green-900' : 'text-gray-900', 'ml-3 font-medium']">{{ plan.name }}</RadioGroupLabel>
                    </div>
                    <RadioGroupDescription class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                      <span :class="[checked ? 'text-green-900' : 'text-gray-900', 'font-medium']">{{ plan.price }} SOL</span>
                      {{ ' ' }}
                      <span :class="checked ? 'text-green-700' : 'text-gray-500'">(~{{ plan.priceFiat }})</span>
                    </RadioGroupDescription>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div class="mt-6">
              <label for="username" class="block text-sm font-medium text-gray-700">Discord username</label>
              <div class="mt-1">
                <input type="text" id="username" v-model="data.discordUser" class="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="johndoe#1234" required />
              </div>
            </div>
            <div class="mt-4">
              <label for="address" class="block text-sm font-medium text-gray-700">Solana address</label>
              <div class="mt-1">
                <input type="text" id="address" v-model="data.address" class="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="HDUfPV...twtK1A" required />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <DBtn type="submit" :loading="loading" color="green" primary class="sm:ml-3 sm:w-auto">
        Register
      </DBtn>
      <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" @click="emit('close')">
        Cancel
      </button>
    </div>
  </form>
</template>

<script setup>
/* global _gs */
import { reactive, inject } from 'vue'
import { DialogTitle, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { IdentificationIcon } from '@heroicons/vue/outline'
import { notify } from '@kyvg/vue3-notification'
import { useMutation } from '@vue/apollo-composable'
import { whitelistRegister } from '@/gql/presale'
import { DBtn } from '@ui'

const drop = inject('drop')
const emit = defineEmits(['close'])
const plans = [
  { name: '1x SNEK', price: 0.55, priceFiat: '$135', quantity: 1 },
  { name: '2x SNEK', price: 1.1, priceFiat: '$270', quantity: 2 },
  { name: '3x SNEK', price: 1.65, priceFiat: '$405', quantity: 3 },
]

const data = reactive({
  dropId: drop.value.id,
  quantity: null,
  discordUser: null,
  address: null,
})

const { mutate, loading } = useMutation(whitelistRegister, () => ({
  variables: data
}))

const save = async (e) => {
  e.preventDefault()

  if (![1, 2, 3, 4].includes(data.quantity)) {
    return notify({ type: 'error', text: 'Select the quantity you want to mint' })
  }

  try {
    await mutate()
    notify({ type: 'success', text: 'Registered for whitelist' })
    emit('close')
    _gs('event', 'Whitelist registration', {
      quantity: data.quantity,
      drop: drop.value.slug
    })
  } catch (err) {
    notify({ type: 'error', title: 'Unexpected error', text: err.message })
  }
}
</script>